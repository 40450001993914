import { ENVELOPE_TYPE } from './enums';

function readCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length >= 2) {
    return decodeURIComponent(parts.pop().split(';').shift());
  }
  return null;
}

/**
 * @param {string} envelopeType - i.e. '_lr_atsDirect'
 * @returns {string[] | null} - if envelope is stored, returns parsed ATS Direct deals: ['12345', '234565']. If there is no envelope returns null
 */
function readStoredEnvelope(envelopeType = ENVELOPE_TYPE.ATS_DIRECT) {
  const cookieEnvelope = readCookie(envelopeType);
  const storageEnvelope = localStorage.getItem(envelopeType);
  if (cookieEnvelope) {
    return JSON.parse(atob(cookieEnvelope)).envelope;
  }
  if (storageEnvelope) {
    return JSON.parse(atob(storageEnvelope)).envelope;
  }
  return null;
}

export {
  readStoredEnvelope,
};
